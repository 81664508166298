import React, { useCallback, useEffect, useMemo } from 'react';
import Recaptcha from 'react-google-recaptcha';
import { CheckboxField, Form, FormError, TextInputField, useCurrentForm } from '@ci/forms';
import { FieldGrid } from '@ci/layout';
import { Spinner } from '@ci/atoms';
import { PasswordInputField } from '@myci/password';
import { useDispatch, useSelector } from 'react-redux';
import { getUserType, setCaptchaResponse } from '@myci/domain-registration';
import { USERS_DICT, getIsUserTypeNumberCompany } from '@myci/domain-subscriptions';
import { Message } from '@ci/message';
import {
	RegistrationStepWrapper,
	TermsAndConditionsTextContainer,
	messages as registrationMessages,
} from '@myci/module-registration';
import {
	fetchTermsAndConditions,
	selectTermsAndConditions,
	useRemoteInstanceConfig,
} from '@myci/domain-data-api';
import { prepareStyle, useStyles } from '@ci/styles';
import { MandatoryFieldsText, Textarea } from '@myci/ui-components';
import { getCurrentInstanceEnv } from '@myci/utils';
import { PhoneInputField } from '@myci/phone-numbers';
import { getLocale } from '@myci/intl';

import m from '../../messages';
import { moduleConfig } from '../config';

const { recaptchaSiteKey } = getCurrentInstanceEnv();

const termsWrapperStyle = prepareStyle(utils => ({
	marginBottom: utils.spacings.md,
	marginTop: utils.spacings.md,
}));

const captchaWrapperStyle = prepareStyle(utils => ({
	margin: `${utils.spacings.lg} ${utils.spacings.md}`,
}));

export const GeneralInfoStep = () => {
	const { enableCaptchaValidation: isCaptchaEnabled } = useRemoteInstanceConfig() ?? {};
	const dispatch = useDispatch();
	const { applyStyle } = useStyles();
	const form = useCurrentForm();
	const userType = useSelector(getUserType);
	const isCompany = getIsUserTypeNumberCompany(userType);
	const termsAndConditions = useSelector(selectTermsAndConditions);
	const { settings } = moduleConfig;
	const locale = useSelector(getLocale);

	const { defaultPhoneInputCountry } = settings;

	useEffect(() => {
		if (userType) {
			dispatch(fetchTermsAndConditions(USERS_DICT[userType].toLowerCase()));
		}
	}, [dispatch, userType]);

	const confirmCaptchaVerification = useCallback(
		(captchaResponse: string) => {
			dispatch(setCaptchaResponse(captchaResponse));
		},
		[dispatch]
	);

	const individualRows = [
		[<TextInputField label={<Message {...m.fieldNationalId} />} name="nationalId" isDisabled />],
		[
			<TextInputField label={<Message {...m.fieldName} />} name="name" isDisabled />,
			<TextInputField label={<Message {...m.fieldSurname} />} name="surname" isDisabled />,
		],
		[
			<TextInputField name="email" label={<Message {...registrationMessages.fieldEmail} />} />,
			<TextInputField
				name="confirmEmail"
				label={<Message {...registrationMessages.fieldEmailConfirm} />}
			/>,
		],
		[
			<PasswordInputField
				label={<Message {...registrationMessages.fieldPassword} />}
				name="password"
				hasRulesPopover
			/>,
			<PasswordInputField
				label={<Message {...registrationMessages.fieldPasswordConfirm} />}
				name="confirmPassword"
			/>,
		],
	];

	const companyRows = useMemo(
		() => [
			[
				<TextInputField
					label={<Message {...registrationMessages.fieldCompanyName} />}
					name="name"
				/>,
				<TextInputField
					label={<Message {...m.fieldRegistrationNumber} />}
					name="registrationNumber"
				/>,
			],
			[
				<TextInputField name="email" label={<Message {...registrationMessages.fieldEmail} />} />,
				<TextInputField
					name="confirmEmail"
					label={<Message {...registrationMessages.fieldEmailConfirm} />}
				/>,
			],
			[
				<PasswordInputField
					label={<Message {...registrationMessages.fieldPassword} />}
					name="password"
					hasRulesPopover
				/>,
				<PasswordInputField
					label={<Message {...registrationMessages.fieldPasswordConfirm} />}
					name="confirmPassword"
				/>,
			],
			[
				<PhoneInputField
					label={<Message {...registrationMessages.fieldPhoneNumber} />}
					name="phoneNumber"
					defaultCountry={defaultPhoneInputCountry}
				/>,
			],
		],
		[defaultPhoneInputCountry]
	);

	if (!userType) {
		return <Spinner />;
	}
	return (
		<RegistrationStepWrapper>
			<Form form={form}>
				<FormError form={form} variant="text" verticalMargin={{ top: 'md' }} />
				<FieldGrid key={String(isCompany)} rows={isCompany ? companyRows : individualRows} />
				<div className={applyStyle(termsWrapperStyle)}>
					{termsAndConditions?.data ? (
						<TermsAndConditionsTextContainer html={termsAndConditions.data} />
					) : (
						<Textarea
							className="text-body"
							label={<Message {...registrationMessages.fieldTermsConditions} />}
							name="terms-and-conditions"
							rows={10}
							value={registrationMessages.termsConditions.defaultMessage}
							readOnly
						/>
					)}
				</div>
				<CheckboxField
					label={<Message {...registrationMessages.confirmTermsConditions} />}
					name="agree"
				/>
				{isCaptchaEnabled && (
					<div className={applyStyle(captchaWrapperStyle)}>
						<Recaptcha
							sitekey={recaptchaSiteKey}
							onChange={confirmCaptchaVerification}
							hl={locale}
						/>
					</div>
				)}
				<MandatoryFieldsText mb={4} />
			</Form>
		</RegistrationStepWrapper>
	);
};
