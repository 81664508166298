import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, useCurrentForm } from '@ci/forms';
import { Spinner, Text } from '@ci/atoms';
import { prepareStyle, useStyles } from '@ci/styles';
import {
	bankLinkMethodManager,
	fetchBankLinkMethods,
	performBankLinkAuthorization,
	selectIsFetchingBankLinkMethods,
} from '@myci/domain-instance-lva';
import { RegistrationStepWrapper } from '@myci/module-registration';
import { Message } from '@myci/intl';
import { ExternalAuthButtons } from './ExternalAuthButtons';
import m from '../../messages';

const stepContentStyle = prepareStyle(() => ({
	alignItems: 'center',
	display: 'flex',
	flexDirection: 'column',
}));

export const ExternalAuthStep = () => {
	const form = useCurrentForm();
	const dispatch = useDispatch();
	const bankLinkMethods = useSelector(bankLinkMethodManager.select);
	const isFetchingBankLinkMethods = useSelector(selectIsFetchingBankLinkMethods);
	const { applyStyle } = useStyles();

	useEffect(() => {
		if (!bankLinkMethods) {
			dispatch(fetchBankLinkMethods());
		}
	}, [bankLinkMethods, dispatch]);

	const handleAuthorize = useCallback(
		(methodName: string) => {
			dispatch(performBankLinkAuthorization({ methodName }));
		},
		[dispatch]
	);

	return (
		<RegistrationStepWrapper>
			<Form form={form}>
				{!bankLinkMethods || isFetchingBankLinkMethods ? (
					<Spinner />
				) : (
					<div className={applyStyle(stepContentStyle)}>
						<Text weight="bold">
							<Message {...m.chooseBankLinkMethod} />
						</Text>
						<ExternalAuthButtons onMethodSelect={handleAuthorize} />
					</div>
				)}
			</Form>
		</RegistrationStepWrapper>
	);
};
