import React, { useCallback, useMemo } from 'react';
import { clone, identity } from 'ramda';
import { Message, getLocale } from '@myci/intl';
import { USER_TYPE, getIsUserTypeNumberCompany } from '@myci/domain-subscriptions';
import {
	getCaptchaResponse,
	getSubscriptionPlan,
	getUserType,
	paySubscriptionPlan,
	registerUser,
	useValidateEmailAsync,
	verifyEmailCode,
} from '@myci/domain-registration';
import { useDispatch, useSelector } from 'react-redux';
import {
	RegistrationButtonsPropsContext,
	defaultSteps,
	forms,
	messages as registrationMessages,
	usePlanPreselectionEffect,
	useProcessPaymentResult,
} from '@myci/module-registration';
import { hasNoLeadingZero, isPossiblePhoneNumber } from '@myci/phone-numbers';
import { SubmitHandler, WizardStep, WizardSubmitHandler, useForm, useWizard, v } from '@ci/forms';
import { validateEmailsMatch } from '@myci/validations';
import { validatePasswordsConfirm } from '@myci/password';
import { useProcessBankLinkResult, validateNationalId } from '@myci/domain-instance-lva';
import { useRemoteInstanceConfig } from '@myci/domain-data-api';
import { prepareStyle, useStyles } from '@ci/styles';
import { Tabs } from '@ci/tabs';
import { Text } from '@ci/atoms';

import { GeneralInfoStep } from './GeneralInfoStep';
import { ExternalAuthStep } from './ExternalAuthStep';

import m from '../../messages';

const wrapperStyle = prepareStyle(utils => ({
	paddingBottom: utils.spacings.lg,
	paddingTop: utils.spacings.lg,
}));

interface GeneralInfoFormValues {
	confirmEmail: string;
	confirmPassword: string;
	email: string;
	firstName: string;
	lastName: string;
	nationalId: string;
	password: string;
	phoneNumber?: string;
}

interface VerifyEmailFormValues {
	code: string;
}

type WizardFormValues = GeneralInfoFormValues & VerifyEmailFormValues;

const tabs = {
	externalAuth: 'externalAuth',
	generalInfo: 'generalInfo',
	payment: 'payment',
	planSelection: 'planSelection',
	success: 'success',
	verifyEmail: 'verifyEmail',
};

export const RegistrationStepper = () => {
	const { enableCaptchaValidation: isCaptchaEnabled } = useRemoteInstanceConfig() ?? {};
	const dispatch = useDispatch();
	const userType = useSelector(getUserType);

	const culture = useSelector(getLocale);
	const selectedPlan = useSelector(getSubscriptionPlan);
	const isCompany = getIsUserTypeNumberCompany(userType);
	const captchaResponse = useSelector(getCaptchaResponse);
	const { applyStyle } = useStyles();

	const generalInfoUserValidate = v.validate({
		surname: [v.required(), v.maxLength({ length: 128 })],
		nationalId: validateNationalId,
	});

	const generalInfoCompanyValidate = v.validate({
		phoneNumber: [hasNoLeadingZero('LV'), isPossiblePhoneNumber],
		registrationNumber: [v.required(), v.maxLength({ length: 32 })],
	});

	const validateEmailAsync = useValidateEmailAsync<GeneralInfoFormValues>({
		userType,
	});

	const generalInfoForm = useForm<GeneralInfoFormValues>({
		id: forms.generalInfo,
		validate: v.combine(
			v.validate({
				agree: [v.required(), v.checked()],
				name: [v.required(), v.maxLength({ length: 128 })],
				password: [v.required()],
				confirmPassword: [v.required()],
				email: [v.required(), v.email()],
				confirmEmail: [v.required()],
			}),
			isCompany ? generalInfoCompanyValidate : generalInfoUserValidate,
			validatePasswordsConfirm,
			validateEmailsMatch,
			validateEmailAsync
		),
	});

	const handleSubmitVerifyEmail: SubmitHandler<VerifyEmailFormValues> = useCallback(
		async values => {
			const email = generalInfoForm.getValues().email;

			await dispatch(
				verifyEmailCode.dangerously({
					email,
					code: values.code,
				})
			);
		},
		[dispatch, generalInfoForm]
	);

	const verifyEmailForm = useForm({
		id: forms.verifyEmail,
		onSubmit: handleSubmitVerifyEmail,
		validate: v.validate({
			code: [v.required()],
		}),
	});

	const handleSubmitPayment = useCallback(async () => {
		const generalInfoValues = clone(generalInfoForm.getValues());

		// TODO offer alternative next step scenario in case of failure (when we have design)
		await dispatch(
			paySubscriptionPlan.dangerously({
				userName: generalInfoValues.email,
				password: generalInfoValues.password,
			})
		);
	}, [dispatch, generalInfoForm]);

	const paymentForm = useForm({
		defaultValues: {
			dummyPaymentMethod: 'creditCard',
		},
		id: forms.payment,
		validate: v.validate({
			dummyPaymentMethod: [v.required()],
		}),
		onSubmit: handleSubmitPayment,
	});

	const planSelectionForm = useForm({
		id: forms.planSelection,
	});
	const externalAuthForm = useForm({
		id: forms.externalAuth,
	});
	const successForm = useForm({
		id: forms.success,
	});

	const steps: Array<WizardStep<any>> = useMemo(
		() =>
			[
				{
					key: tabs.planSelection,
					label: <Message {...registrationMessages.planSelectionStepLabel} />,
					content: <defaultSteps.PlanSelection />,
					form: planSelectionForm,
					icon: 'billing',
					buttons: {
						primary: {
							isDisabled: !selectedPlan,
							id: 'plan-selection-next',
						},
						secondary: {
							isVisible: false,
						},
					},
				},
				userType === USER_TYPE.Individual && {
					key: tabs.externalAuth,
					label: <Message {...m.externalAuthStepLabel} />,
					content: <ExternalAuthStep />,
					form: externalAuthForm,
					hasSideEffect: true,
					icon: 'idGeneration',
					buttons: {
						primary: {
							isDisabled: true,
						},
					},
				},
				{
					key: tabs.generalInfo,
					content: <GeneralInfoStep />,
					label: <Message {...registrationMessages.generalInfoStepLabel} />,
					form: generalInfoForm,
					icon: 'info',
					buttons: {
						primary: {
							isDisabled: isCaptchaEnabled && !captchaResponse,
						},
						secondary: {
							isVisible: userType !== USER_TYPE.Individual,
						},
					},
				},
				{
					key: tabs.verifyEmail,
					content: <defaultSteps.EmailVerification generalInfoForm={generalInfoForm} />,
					label: <Message {...registrationMessages.emailVerificationStepLabel} />,
					form: verifyEmailForm,
					hasWizardSubmitHandler: true,
					icon: 'contact',
				},
				selectedPlan?.price?.value !== 0 && {
					key: tabs.payment,
					content: <defaultSteps.Payment />,
					label: <Message {...registrationMessages.paymentStepLabel} />,
					form: paymentForm,
					hasSideEffect: true,
					icon: 'payments',
					buttons: {
						primary: {
							children: <Text message={m.payment} />,
						},
					},
				},
				{
					key: tabs.success,
					content: <defaultSteps.Success />,
					label: <Message {...registrationMessages.successStepLabel} />,
					form: successForm,
					hasWizardSubmitHandler: false,
					icon: 'success',
					buttons: {
						primary: {
							isVisible: false,
						},
						secondary: {
							isVisible: false,
						},
					},
				},
			].filter(identity),
		[
			captchaResponse,
			generalInfoForm,
			isCaptchaEnabled,
			verifyEmailForm,
			planSelectionForm,
			externalAuthForm,
			successForm,
			selectedPlan,
			userType,
		]
	);

	const handleWizardSubmit: WizardSubmitHandler<WizardFormValues> = useCallback(
		async formValues => {
			const { email } = formValues;
			await dispatch(
				registerUser.dangerously({
					...formValues,
					userType,
					userName: email,
					culture,
					plan: selectedPlan.key,
				})
			);
		},
		[dispatch, culture, userType, selectedPlan]
	);

	const { formButtonsProps, tabsProps, tabController } = useWizard({
		steps,
		onSubmit: handleWizardSubmit,
	});

	usePlanPreselectionEffect();
	useProcessBankLinkResult({
		tabController,
		generalInfoForm,
	});
	useProcessPaymentResult({
		tabController,
		successStepKey: tabs.success,
		errorStepKey: tabs.payment,
	});

	return (
		<RegistrationButtonsPropsContext.Provider value={formButtonsProps}>
			<div className={applyStyle(wrapperStyle)}>
				<Tabs {...tabsProps} layout="vertical" />
			</div>
		</RegistrationButtonsPropsContext.Provider>
	);
};
