import React from 'react';
import { Message } from '@myci/intl';
import { withReducers } from 'redux-syringe';
import { SubscriptionCardsSkeletor, USERS_DICT } from '@myci/domain-subscriptions';
import { getUserType, reducer as registration } from '@myci/domain-registration';
import { Grid, Heading, Img, SafeSuspense, Section, Text } from '@myci/ui-components';
import m from 'gatsby-theme-myci/src/messages';
import PublicLayout from 'gatsby-theme-myci/src/layouts/PublicLayout';
import { useSelector } from 'react-redux';

import { RegistrationStepper } from '../components/RegistrationStepper';

const Registration = () => {
	const userType = useSelector(getUserType);

	return (
		<PublicLayout
			headerClassName="section--registration"
			header={
				<Section pb={{ xs: 4, lg: 0 }} pt={5}>
					<Grid container>
						<Grid row>
							<Grid
								col={{ xs: 12, lg: 5 }}
								pr={{ md: 0 }}
								offset={{ lg: 2 }}
								order={{ xs: 2, lg: 1 }}
							>
								<Heading align={{ xs: 'center', lg: 'left' }} className="section__heading">
									{userType && <Message {...m[`registrationTitle${USERS_DICT[userType]}`]} />}
								</Heading>
								<Text align={{ xs: 'center', lg: 'left' }} className="text-md" mt={3}>
									<Message {...m.registrationDescription} />
								</Text>
							</Grid>
							<Grid col={{ xs: 12, lg: 5 }} order={{ xs: 1, lg: 2 }} className="text-center">
								<Img
									className="reg-img img-fluid mb-0"
									src={require('@myci/assets/images/reg.png')}
									alt=""
								/>
							</Grid>
						</Grid>
					</Grid>
				</Section>
			}
		>
			<SafeSuspense fallback={<SubscriptionCardsSkeletor />}>
				<Section>
					<Grid container>
						<Grid row>
							<Grid col={12}>
								<RegistrationStepper />
							</Grid>
						</Grid>
					</Grid>
				</Section>
			</SafeSuspense>
		</PublicLayout>
	);
};

export default withReducers({ registration }, { isGlobal: true })(Registration);
